<template>
  <!--  -->
  <!-- 多媒体管理 -->
  <div class="cs">
    <div>
      <!-- 搜索框这行的大div -->
      <div class="multimediaManagement-two-big flex">
        <!-- 左div-->
        <div class="multimediaManagement-left flex">
          <div>图片 (共{{ total }}条)</div>
        </div>
        <!-- 右边 -->
        <div class="multimediaManagement-right flex">
          <el-button
            size="small"
            type="primary"
            round
            @click="createPictureFun()"
            >同步素材</el-button
          >
          <el-upload
            class="upload-demo"
            action="https://wxappvue.df01.top/api/picture/uploadFile"
            :data="upImgMsg"
            :on-success="onSuccess"
            multiple
            :show-file-list="false"
            :limit="50"
          >
            <el-button size="small" type="success" round>点击上传</el-button>
          </el-upload>
        </div>
      </div>
      <!-- 图片列表 包括分页-->
      <div class="imgs">
        <!-- 图片列表循环 -->
        <div class="imagesList flex">
          <!-- 个体图片 -->
          <div class="imagesDate" v-for="item in images">
            <!-- <div class="image"> -->
            <div>
              <div class="image-button">
                <div class="image-button-left"></div>
                <!-- <div class="image-button-right flex">
                  <el-dropdown>
                    <el-button icon="el-icon-more-outline" circle></el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="deletePictureFun(item.id)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div> -->
              </div>
            </div>
            <!-- -->
            <el-image
              @click="toMaxImg(item.url)"
              class="image"
              :src="item.url"
              :preview-src-list="srcList"
            >
              <!-- 图片上方按钮 -->
            </el-image>
          </div>
        </div>

        <!-- 分页 -->
        <!--
         // 个数
           pagesize: Number,
         // 当前页数
           currentPage: Number,1
         // 总数
            total: Number,
         -->
        <div class="pagination">
          <pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :total="total"
            :pagesize="pagesize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </div>
      <!-- <el-tab-pane label="音频" name="second">敬请期待 </el-tab-pane>
          <el-tab-pane label="视频" name="third">敬请期待 </el-tab-pane> -->
    </div>
  </div>
</template>

<script>
import {
  selectAllPicture,
  // uploadFile,
  deletePicture,
  createPicture,
} from '@/request/multimediaManagement'
import { reactive, toRefs, inject } from 'vue'
// 导入分页组件
import pagination from '@/components/pagination.vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'image',
  components: { pagination },
  setup(props, context) {
    // 引用外部组件的 tencentId
    const tencentId = inject('tencentId').value
    const parent = { ...context }
    const state = reactive({
      // 上传图片参数
      upImgMsg: {
        id: tencentId,
        type: 'image',
      },

      // 进入页面默认显示被关注回复
      activeName: 'first',
      backcolor: 'false',
      srcList: [],
      images: [],
      totalSize: '',
      // 条数
      pagesize: 16,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 100,
    })
    // 获取图片信息
    const selectAllPictureFun = () => {
      const datas = {
        id: tencentId,
        currentPage: state.currentPage,
        size: state.pagesize,
      }

      selectAllPicture(datas).then((res) => {
        state.images = res.data.data
        state.currentPage = res.data.currenPage
        state.total = res.data.totalSize
      })
    }

    // 上传图片成功
    const onSuccess = () => {
      selectAllPictureFun()
    }

    selectAllPictureFun()

    // // 删除图片
    const deletePictureFun = (data) => {
      const datas = { id: data }

      deletePicture(datas).then(() => {
        ElMessage({
          type: 'success',
          message: '删除成功',
          // 提交表单
        })
        selectAllPictureFun()
      })
    }

    // // 同步素材
    const createPictureFun = () => {
      const datas = { id: tencentId }

      createPicture(datas).then(() => {
        ElMessage({
          type: 'success',
          message: '同步素材成功',
          // 提交表单
        })
        selectAllPictureFun()
      })
    }

    // 把选择的图片给父级
    const toMaxImg = (val) => {
      // state.srcList[0] = val
      parent.emit('imgUrl', val)
    }

    // 分页开始
    // 页数 发生改变
    const handleCurrentChange = (e) => {
      state.currentPage = e
      selectAllPictureFun()
    }
    // 个数 发生改变
    const handleSizeChange = (e) => {
      state.pagesize = e
      selectAllPictureFun()
    }

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      toMaxImg,
      selectAllPictureFun,
      handleCurrentChange,
      handleSizeChange,
      onSuccess,
      deletePictureFun,
      createPictureFun,
    }
  },
}
</script>
<style lang="scss" scoped>
.multimediaManagement-two-big {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.multimediaManagement-left {
  width: 50%;
}

.multimediaManagement-right {
  justify-content: flex-end;
}

.imgs {
  width: 100%;
  height: 750px;
}

.imagesList {
  max-width: 100%;
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  box-sizing: border-box;
}

.imagesDate {
  margin: 10px;
  height: 16%;
  width: 22%;
}

.image {
  width: 100%;
  border: 2px solid #fff;
  height: 80%;
}
.image:hover {
  border: 2px solid rgb(22, 231, 85);
  cursor: pointer;
  // display: none;
}
.image-text {
  padding-top: 10px;
  text-align: center;
}

.image-button-right {
  justify-content: flex-end;
}
</style>
