/**
 * 首页管理api接口统一管理
 */
import { get, post } from './http'

/**
 * 查询首页
 * @param {*} data
 * @returns
 */
export const view = (data) => get('/index/view', data)

/**
 * 进入公众号用户数据分析
 * @param {*} data
 * @returns
 */
export const getWxuserInfo = (data) => get('/user/getWxuserInfo', data)


/**
 * 登录
 * @param {*} data
 * @returns
 */
export const login = (data) => post('/user/login ', data)


/**
 * 路由
 * @param {*} data
 * @returns
 */
export const getPermissionList = (data) => get('/user/getPermissionList ', data)


/**
 * 修改密码
 * @param {*} data
 * @returns
 */
export const updatePassword = (data) => post('user/updatePassword ', data)

/**
 * 获取公众号列表
 * @param {*} data
 * @returns
 */
export const selectGroupByAccountInfo = (data) => get('/index/selectGroupByAccountInfo', data)


