<template>
  <!-- 标题组 -->
  <div>
    <el-card>
      <div class="flex">
        <div v-for="(item, index) in sucaiData">
          <div class="image-container" :style="item.type==1?'background-color: #A1F6A3':'background-color: #55BDF8'" >
            <img :src="item.imageUrl" width="60" height="100" />
            <i class="el-icon-error" @click="deletevideo(index)"></i>
          </div>
        </div>
      </div>
      <div>已选：{{sucaiData.length  }}个素材</div>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch, defineComponent } from 'vue'
import { login } from '@/request'
import Image from '@/components/image.vue'
import { stringify } from 'qs'

export default {
  name: 'showVideo',
  components: { Image },
  // 接收页面的传值
  props: ['sucaiData', 'area', 'index'],
  setup(props, context) {
    const state = reactive({
      // 选中的素材数据
      checkedCities: [],
      //素材数据
      sucaiData: props.sucaiData,
      //区域
      area: props.area,
      //组件索引
      index: props.index,

      // 素材改变
      deletevideo: (index) => {
        console.log('sucaiData',state.sucaiData)
        if (state.area == 'on') {
          console.log(
            '%c [props.index  ]-43',
            'font-size:13px; background:pink; color:#bf2c9f;',
            state.index
          )

          console.log(
            '%c [ index ]-48',
            'font-size:13px; background:pink; color:#bf2c9f;',
            index
          )
          context.emit('getsucaiDataChange', index, state.index)
        } else {
          context.emit('getsucaiDataChange', index)
        }
      },
    })

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
    }
  },
}
</script>

<style lang="scss" scoped>
.el-icon-error {
  position: absolute;
  top: -10px; /* 调整按钮的位置 */
  right: -10px; /* 调整按钮的位置 */
  font-size: 20px;
  // display: none;
  cursor: pointer;
}

.el-main {
  --el-main-padding: 18px;
}

.el-aside {
  width: 26%;
}

.add {
  div {
    padding-right: 50px;
  }
}

.left-main {
  > div {
    margin-bottom: 10px;
  }
}

.nameDiv-y {
  // overflow-y: auto;
}

.addTitleBtn {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.mainBtn {
  margin-bottom: 10px;
  margin-top: 10px;
  > div {
    margin-right: 40px;
  }
}
.title-tbn {
  height: 40px;
  line-height: 40px;
  // width: 30%;
  > div {
    padding-right: 4px;
  }
}
.nameDiv {
  // border: 2px solid rgb(220, 223, 230);
  width: 72%;
  justify-content: space-between;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
}

.v-for-div {
  align-content: center;
  border: 1px solid rgb(220, 223, 230);
  cursor: pointer;
  height: 40px;
  padding-left: 4px;
  box-sizing: border-box;
  border-radius: 1px;
  // > div {
  //   margin-top: 2px;
  // }
}

.v-for-div-color {
  background-color: rgb(102, 177, 255);
}

.v-for-div:hover {
  border: 1px solid rgb(102, 177, 255);
}
.tableBtn {
  > div {
    margin-bottom: 40%;
  }
}

.v-for-videoList {
  // width: 270px;
  // height: 480px;
  // margin-right: 10px;
  width: 160px;
  height: 240px;
  margin-right: 10px;
}

.videoListdiv {
  flex-wrap: wrap;
  overflow-y: auto;
}

.video-card {
  > div {
    margin-bottom: 10px;
  }
}

.image-container {
  position: relative;
  margin-left: 20px;
  width: 80px;
  height: 120px;
}

.image-container img {
  width: 60px;
  height: 100px;
  padding-left: 10px;
  padding-top: 10px;
}

.icon-error {
  position: absolute;
  top: 0;
  right: 0;
}

.avatar-uploader-icon {
  display: block;
  font-size: 180px;
}

.tips {
  margin-top: 10px;
  color: rgb(245, 8, 8);
}

.textBtn {
  justify-content: space-between;
}

.Ftree {
  margin-right: 30px;
}

.downBtn {
  justify-content: flex-end;
  padding: 10px 10px 0px 15px;
  > div {
    padding-left: 30px;
  }
}
//
</style>
