/**
 * 多媒体管理api接口统一管理
 */
import { get, post } from './http'


/**
 * 查询指定公众号的所有图片信息
 * @param {*} data
 * @returns
 */
export const selectAllPicture = (data) => get('/picture/selectAllPicture', data)

/**
 * 上传图片
 * @param {*} data
 * @returns
 */
export const uploadFile = (data) => get('/picture/uploadFile', data)

/**
 * 删除图片
 * @param {*} data
 * @returns
 */
export const deletePicture = (data) => get('/picture/deletePicture', data)


/**
 * 同步图片
 * @param {*} data
 * @returns
 */
export const createPicture = (data) => get('/picture/createPicture', data)
